/****************************default Greeting audio ************************/
let DEV_PATH = "http://localhost:7071"
//let PROD_PATH = "https://heedifyprovisioningservices.azurewebsites.net"
let PROD_2_PATH = "https://func-provisioning-prod-ne.azurewebsites.net"
let PROD_PATH = "https://provisioning.heedify.io"
let currentPath = PROD_PATH
export const defaultCallFlowGreetingAudio =
  "https://callcenterconf.blob.core.windows.net/heedifymedia/defaultGreeting/Default_Greeting.wav";
export const defaultQueueMusicOnHold =
  "https://callcenterconf.blob.core.windows.net/tenants/de5e3c28-141c-4d3e-90ea-aa3326abe67d/default/media/Urban Sunrise.wav";

/****************************AgentManagement ************************/

export const addAgentUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/AgentManagement/" +
    tenantId +
    "/" +
    scope +
    "/add/agent?userid=" +
    userId
  );
};
export const updateAgentUrl = (tenantId, userId, agentId, scope) => {
  return (
    currentPath + "/AgentManagement/" +
    tenantId +
    "/" +
    scope +
    "/update/" +
    agentId +
    "?userid=" +
    userId
  );
};
export const deleteAgentUrl = (tenantId, userId, agentId, scope) => {
  return (
    currentPath + "/AgentManagement/" +
    tenantId +
    "/" +
    scope +
    "/remove/" +
    agentId +
    "?userid=" +
    userId
  );
};
export const selectAgentUrl = (tenantId, userId, agentId) => {
  return (
    currentPath + "/AgentManagement/" +
    tenantId +
    "/default/get/" +
    agentId +
    "?userid=" +
    userId
  );
};
export const getAllAgentsUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/AgentManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/ALL?userid=" +
    userId
  );
};

/****************************Upload Media ************************/
export const uploadMediaUrl = (tenantId, userId, FileName) => {
  return (
    currentPath + "/UploadMedia/" +
    tenantId +
    "/default/?userid=" +
    userId +
    "&filename=" +
    FileName +
    "&code=LSragTJktQktDqc869wAvUPX/rAqKgnytMhsnqc9RRY/9BXqdGnT/w=="
  );
};
/****************************AudioManagement ************************/
export const deleteAudioManagementUrl = (tenantId, mediaName, Token) => {
  return (
    currentPath + "/AudioManagement/" +
    tenantId +
    "/default/delete/" +
    mediaName +
    "?token=" +
    Token
  );
};
export const getAllAudioManagementUrl = (tenantId, Token) => {
  return (
    currentPath + "/AudioManagement/" +
    tenantId +
    "/default/get/All?token=" +
    Token
  );
};
/****************************CallFlowManagement ************************/
export const selectCallFlowManagementUrl = (
  tenantId,
  userId,
  CallFlowId,
  scope
) => {
  return (
    currentPath + "/CallFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/" +
    CallFlowId +
    "?userid=" +
    userId
  );
};
export const addCallFlowManagementUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/CallFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/add/null?userid=" +
    userId
  );
};
export const updateCallFlowManagementUrl = (
  tenantId,
  userId,
  CallFlowId,
  scope
) => {
  return (
    currentPath + "/CallFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/update/" +
    CallFlowId +
    "?userid=" +
    userId
  );
};
export const removeCallFlowManagementUrl = (
  tenantId,
  userId,
  CallFlowId,
  scope
) => {
  return (
    currentPath + "/CallFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/remove/" +
    CallFlowId +
    "?userid=" +
    userId
  );
};
export const getAllCallFlowManagementUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/CallFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/ALL?userid=" +
    userId
  );
};
/****************************ChatQueuesManagement ************************/
export const selectChatQueueManagementUrl = (tenantId, Token, ChatQueueId) => {
  return (
    currentPath + "/ChatQueueManagement/" +
    tenantId +
    "/default/get/" +
    ChatQueueId +
    "?token=" +
    Token
  );
};
export const addChatQueueManagementUrl = (tenantId, Token, ChatQueueId) => {
  return (
    currentPath + "/ChatQueueManagement/" +
    tenantId +
    "/default/add/" +
    ChatQueueId +
    "/?token=" +
    Token
  );
};
export const updateChatQueueManagementUrl = (tenantId, Token, ChatQueueId) => {
  return (
    currentPath + "/ChatQueueManagement/" +
    tenantId +
    "/default/update/" +
    ChatQueueId +
    "?token=" +
    Token
  );
};
export const getAllChatQueuesManagementUrl = (tenantId, Token) => {
  return (
    currentPath + "/ChatQueueManagement/" +
    tenantId +
    "/default/get/ALL?token=" +
    Token
  );
};
export const removeChatQueueManagementUrl = (tenantId, Token, ChatQueueId) => {
  return (
    currentPath + "/ChatQueueManagement/" +
    tenantId +
    "/default/delete/" +
    ChatQueueId +
    "?token=" +
    Token
  );
};
/****************************ChatflowManagement ************************/
export const selectChatFlowManagementUrl = (
  tenantId,
  Token,
  ChatflowId,
  scope
) => {
  return (
    currentPath + "/ChatFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/" +
    ChatflowId +
    "?token=" +
    Token
  );
};
export const addChatFlowManagementUrl = (
  tenantId,
  Token,
  ChatFlowId,
  scope
) => {
  return (
    currentPath + "/ChatFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/add/" +
    ChatFlowId +
    "/?token=" +
    Token
  );
};
export const updateChatFlowManagementUrl = (
  tenantId,
  Token,
  ChatFlowId,
  scope
) => {
  return (
    currentPath + "/ChatFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/update/" +
    ChatFlowId +
    "?token=" +
    Token
  );
};
export const getAllChatFlowManagementUrl = (tenantId, Token, scope) => {
  return (
    currentPath + "/ChatFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/ALL?token=" +
    Token
  );
};
export const removeChatFlowManagementUrl = (
  tenantId,
  Token,
  ChatFlowId,
  scope
) => {
  return (
    currentPath + "/ChatFlowManagement/" +
    tenantId +
    "/" +
    scope +
    "/delete/" +
    ChatFlowId +
    "?token=" +
    Token
  );
};
/****************************QueueManagement ************************/
export const AddQueueManagementUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/QueueManagement/" +
    tenantId +
    "/" +
    scope +
    "/add/queue?userid=" +
    userId
  );
};
export const UpdateQueueManagementUrl = (tenantId, userId, queueId, scope) => {
  return (
    currentPath + "/QueueManagement/" +
    tenantId +
    "/" +
    scope +
    "/update/" +
    queueId +
    "?userid=" +
    userId
  );
};
export const getAllQueueManagementUrl = (tenantId, userId, scope) => {
  return (
    currentPath + "/QueueManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/ALL?userid=" +
    userId
  );
};
export const RemoveQueueManagementUrl = (tenantId, userId, queueId, scope) => {
  return (
    currentPath + "/QueueManagement/" +
    tenantId +
    "/" +
    scope +
    "/remove/" +
    queueId +
    "?userid=" +
    userId
  );
};
export const getQueueManagementURL = (tenantId, userId, queueId, scope) => {
  return (
    currentPath + "/QueueManagement/" +
    tenantId +
    "/" +
    scope +
    "/get/" +
    queueId +
    "?userid=" +
    userId
  );
};
/****************************************AADProvisioningGroup Management *************************************** */
export const addAADProvisioningGroupManagementURL = (
  tenantId,
  groupId,
  Token
) => {
  return (
    currentPath + "/AADProvisioningGroupManagement/" +
    tenantId +
    "/add/" +
    groupId +
    "?token=" +
    Token
  );
};
export const getAADProvisioningGroupManagementURL = (tenantId, Token) => {
  return (
    currentPath + "/AADProvisioningGroupManagement/" +
    tenantId +
    "/get/ALL/" +
    "?token=" +
    Token
  );
};
export const updateAADProvisioningGroupManagementURL = (
  tenantId,
  groupId,
  Token
) => {
  return (
    currentPath + "/AADProvisioningGroupManagement/" +
    tenantId +
    "/update/" +
    groupId +
    "?token=" +
    Token
  );
};
export const deleteAADProvisioningGroupManagementURL = (
  tenantId,
  groupId,
  Token
) => {
  return (
    currentPath + "/AADProvisioningGroupManagement/" +
    tenantId +
    "/remove/" +
    groupId +
    "?token=" +
    Token
  );
};
/************************************* groups management *********************** */
export const getAllGroupsUrl = (tenant, scope, userId) => {
  return (
    currentPath + "/GroupManagement/" +
    tenant +
    "/" +
    scope +
    "/get/ALL?userid=" +
    userId
  );
};
export const getGroupUrl = (tenant, scope, groupId, userId) => {
  return (
    currentPath + "/GroupManagement/" +
    tenant +
    "/" +
    scope +
    "/get/" +
    groupId +
    "?userid=" +
    userId
  );
};
export const removeGroupUrl = (tenant, scope, groupId, userId) => {
  return (
    currentPath + "/GroupManagement/" +
    tenant +
    "/" +
    scope +
    "/remove/" +
    groupId +
    "?userid=" +
    userId
  );
};
export const addGroupsUrl = (tenant, scope, userId) => {
  return (
    currentPath + "/GroupManagement/" +
    tenant +
    "/" +
    scope +
    "/create/null?userid=" +
    userId
  );
};
export const updateGroupsUrl = (tenant, scope, userId, groupId) => {
  return (
    currentPath + "/GroupManagement/" +
    tenant +
    "/" +
    scope +
    "/update/" +
    groupId +
    "?userid=" +
    userId
  );
};
/****************************TechnicalAccountManagement ************************/
export const TechnicalAccountManagement =
  currentPath + "/TechnicalAccountManagement/";

/****************************adminconsent ************************/
export const ConsentpresenceUrl = "https://admin.heedify.io/Consentpresence";
export const ConsentccUrl = "https://admin.heedify.io/Consentcc";
export const ProvioningURL =
  currentPath + "/AppConsent/";
export const baseConsentUrl =
  "https://login.microsoftonline.com/common/adminconsent?client_id=";
export const getAdminConsentUrl = (clientId) =>
  "https://login.microsoftonline.com/common/adminconsent?client_id=" + clientId;

/****************************CallQueueReporting ************************/
export const callqueueBackUrl =
  "https://prod-heedify-cq-reports.azurewebsites.net/api/CallQueueReporting/";

/****************************CheckAppConsent ************************/
export const checkConsentUrl =
  "https://provisioning.heedify.io/CheckAppConsent/getConsent";
/****************************text2speechApi ************************/
export const text2speechApiUrl =
  "https://tts.heedify.io/api/text2Speech";
/****************************GRAPH ************************/
export const getUserPhotoUrl = (PersonId) => {
  return "https://graph.microsoft.com/v1.0/users/" + PersonId + "/photo/$value";
};
export const getSearchResultUrl = (searchString) => {
  return (
    "https://graph.microsoft.com/v1.0/users?$select=displayName,id,accountEnabled,userPrincipalName,givenName,displayName" +
    "&$filter=startswith(displayName,'" +
    searchString +
    "' )" +
    " or startswith(userPrincipalName,'" +
    searchString +
    "' )" +
    " or startswith(givenName,'" +
    searchString +
    "' )" +
    " or startswith(surname,'" +
    searchString +
    "' )" +
    " or startswith(mail,'" +
    searchString +
    "' )"
  );
};
export const getSearchPersonUrl = (searchString) => {
  return (
    "https://graph.microsoft.com/v1.0/me/people/?$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'&$search=\"" +
    searchString +
    '"'
  );
};
export const searchUserUrl = (searchString) => {
  return "https://graph.microsoft.com/v1.0/users/" + searchString;
};
/*****************************Licences****************************/
export const getAllLicences = (tenantId, Token, scope) => {
  return (
    currentPath + "/LicencesManagement/" +
    tenantId +
    "/" +
    scope +
    "/get?token=" +
    Token
  );
};
/*****************************Scops****************************/
export const getAllScopsUrl = (tenantId, Token) => {
  return (
    currentPath + "/ScopeManagement/" +
    tenantId +
    "/get/ALL?token=" +
    Token
  );
};
export const deleteScopeUrl = (tenantId, scope, Token) => {
  return (
    currentPath + "/ScopeManagement/" +
    tenantId +
    "/remove/" +
    scope +
    "?token=" +
    Token
  );
};
export const addScopeUrl = (tenantId, scope, Token) => {
  return (
    currentPath + "/ScopeManagement/" +
    tenantId +
    "/add/" +
    scope +
    "?token=" +
    Token
  );
};
export const getScopeUrl = (tenantId, scope, Token) => {
  return (
    currentPath + "/ScopeManagement/" +
    tenantId +
    "/get/" +
    scope +
    "?token=" +
    Token
  );
};
/****************************AdminManagement ************************/
export const adminUsersApiUrl =
  currentPath + "/UserManagement/";
/***************************channelManagement***********************/
export const getAllChannelsFlowsUrl = (tenant, scope, flowId, Token) => {
  return (
    currentPath + "/ChannelManagement/" +
    tenant +
    "/" + scope +
    "/" + flowId + "/get/ALL" +
    "?token=" +
    Token
  )
}
export const getchannelFromConsmosDbUrl = (tenant, scope, flowId, channelId, Token) => {
  return (
    currentPath + "/ChannelManagement/" +
    tenant +
    "/" + scope +
    "/" + flowId + "/get/" + channelId +
    "?token=" +
    Token
  )
}
export const assigneChannelUrl = (tenant, scope, flowId, channelId, Token) => {
  return (
    currentPath + "/ChannelManagement/" +
    tenant +
    "/" + scope +
    "/" + flowId + "/assigneChannel/" + channelId +
    "?token=" +
    Token
  )
}
export const unAssigneChannelUrl = (tenant, scope, flowId, channelId, Token) => {
  return (
    currentPath + "/ChannelManagement/" +
    tenant +
    "/" + scope +
    "/" + flowId + "/unAssigneChannel/" + channelId +
    "?token=" +
    Token
  )
}
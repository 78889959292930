import React, { useState, useEffect } from "react";
import { Input, Header, Dropdown, Button, Popup } from "@fluentui/react-northstar";
import { useIntl } from "react-intl";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import { authContext } from "../../auth";
import axios from "axios";
import { getAllCallFlowManagementUrl } from '../../urls'
const ReportComponent = (props) => {
    const intl = useIntl();
    const [scopeCallFlows, setScopeCallFlows] = useState([]);
    const reportTemplates = [
        {
            header: "Default",
            value:
                "https://callcenterconf.blob.core.windows.net/pbi/default-templates/en-US/heedify-template.pbix",
        },
    ];
    // Generalized input handler for numeric fields
    const handleInputChange = (field) => (event) => {
        const newValue = parseInt(event.target.value, 10) || 0; // Ensure numeric value
        props.setCallCenterData((prevState) => ({
            ...prevState,
            reportConfiguration: {
                ...prevState.reportConfiguration,
                pbiParams: {
                    ...prevState.reportConfiguration.pbiParams,
                    [field]: newValue,
                },
            },
        }));
    };
    const {
        QD_THRESHOLD,
        QA_THRESHOLD,
        SA_THRESHOLD,
        DA_THRESHOLD,
        ABANDON_TIME_MAX,
        ABANDON_TIME_TARGET,
        TALKING_TIME_MAX,
        TALKING_TIME_TARGET,
        WAITING_TIME_MAX,
        WAITING_TIME_TARGET,
    } = props.reportConfiguration.pbiParams;
    useEffect(() => {
        const url = getAllCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, props.scope);
        axios.get(url).then((res) => {
            const transformedData = Object.entries(res.data).map(([key, obj]) => ({
                value: key,
                header: obj.displayName, // Assuming the inner object has a 'displayName' property
            }));

            setScopeCallFlows(transformedData);
        })
    }, [])
    return (
        <div>
            <Header as="h4" content={intl.formatMessage({ id: "THRESHOLD" })} />

            <div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        className="report-label"
                        as="h5"
                        content={`${intl.formatMessage({ id: "QUICK_DROP_THRESHOLD" })} (sec)`}
                    />
                    <Popup
                        className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "QD_THRESHOLD_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                < Input
                    className="ivr-name-input"
                    type="number"
                    value={QD_THRESHOLD}
                    onChange={handleInputChange("QD_THRESHOLD")}
                />
            </div>

            {/* Repeat for other fields */}
            <div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        className="report-label"
                        as="h5"
                        content={`${intl.formatMessage({ id: "QUICK_ANSWER_THRESHOLD" })} (sec)`}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "QA_THRESHOLD_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                < Input
                    className="ivr-name-input"
                    type="number"
                    value={QA_THRESHOLD}
                    onChange={handleInputChange("QA_THRESHOLD")}
                />
            </div>

            < div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        className="report-label"
                        as="h5"
                        content={`${intl.formatMessage({ id: "SA_THRESHOLD" })} (sec)`}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "SA_THRESHOLD_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                < Input
                    className="ivr-name-input"
                    type="number"
                    value={SA_THRESHOLD}
                    onChange={handleInputChange("SA_THRESHOLD")}
                />
            </div>
            < div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        className="report-label"
                        as="h5"
                        content={`${intl.formatMessage({ id: "DELAYED_ANSWER_THRESHOLD" })} (sec)`}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "DA_THRESHOLD_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                < Input
                    className="ivr-name-input"
                    type="number"
                    value={DA_THRESHOLD}
                    onChange={handleInputChange("DA_THRESHOLD")}
                />
            </div>
            < Header
                as="h4"
                content={intl.formatMessage({ id: "ABANDON_TIME" })}
            />
            <div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'ABANDON_TIME_MAX' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "ABANDON_TIME_MAX_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={ABANDON_TIME_MAX}
                    onChange={handleInputChange("ABANDON_TIME_MAX")}
                />
            </div>
            < div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'ABANDON_TIME_TARGET' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "ABANDON_TIME_TARGET_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={ABANDON_TIME_TARGET}
                    onChange={handleInputChange("ABANDON_TIME_TARGET")}
                />
            </div>
            < Header
                as="h4"
                className="report-label"
                content={intl.formatMessage({ id: "TALKING_TIME" })}
            />
            <div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'TALKING_TIME_MAX' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "TALKING_TIME_MAX_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={TALKING_TIME_MAX}
                    onChange={handleInputChange("TALKING_TIME_MAX")}
                />
            </div>
            < div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'TALKING_TIME_TARGET' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "TALKING_TIME_TARGET_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={TALKING_TIME_TARGET}
                    onChange={handleInputChange("TALKING_TIME_TARGET")}
                />
            </div>
            < Header
                as="h4"
                content={intl.formatMessage({ id: "WAITING_TIME" })}
            />
            <div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'WAITING_TIME_MAX' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "WAITING_TIME_MAX_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={WAITING_TIME_MAX}
                    onChange={handleInputChange("WAITING_TIME_MAX")}
                />
            </div>
            < div className="report-params-div" >
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'WAITING_TIME_TARGET' }) + "  (sec)"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "WAITING_TIME_TARGET_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Input
                    type='number'
                    className="ivr-name-input"
                    value={WAITING_TIME_TARGET}
                    onChange={handleInputChange("WAITING_TIME_TARGET")}
                />
            </div>
            < Header as="h4" content={intl.formatMessage({ id: "EXTENDED_FLOW" })} />
            <div className="report-params-div">
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'EXTENDED_FLOW' }) + " 1"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "EXTENDED_FLOW_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Dropdown
                    className="from-item"
                    clearable
                    items={scopeCallFlows}
                    value={scopeCallFlows.find(
                        (item) =>
                            item.value.replace(/-/g, "") ===
                            props.reportConfiguration.pbiParams.EXT1_FLOW?.match(/app([a-zA-Z0-9]+)/)?.[1]
                    )}
                    onChange={(e, data) => {
                        if(data.value === null){
                            props.setCallCenterData((prevState) => ({
                                ...prevState,
                                reportConfiguration: {
                                    ...prevState.reportConfiguration,
                                    pbiParams: {
                                        ...prevState.reportConfiguration.pbiParams,
                                        ["EXT1_FLOW"]: null,
                                    },
                                },
                            }));
                        }else{
                            let EXTENDED_FLOW_1 = "flow" + authContext._user.profile.tid.split("-")[0] + "app" + data.value.value
                            .replace(/-/g, "")
                        props.setCallCenterData((prevState) => ({
                            ...prevState,
                            reportConfiguration: {
                                ...prevState.reportConfiguration,
                                pbiParams: {
                                    ...prevState.reportConfiguration.pbiParams,
                                    ["EXT1_FLOW"]: EXTENDED_FLOW_1,
                                },
                            },
                        }));
                        }
                        
                    }}
                    checkable
                />
            </div>
            <div className="report-params-div">
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'EXTENDED_FLOW' }) + " 2"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "EXTENDED_FLOW_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Dropdown
                    className="from-item"
                    items={scopeCallFlows}
                    clearable
                    value={scopeCallFlows.find(
                        (item) =>
                            item.value.replace(/-/g, "") ===
                            props.reportConfiguration.pbiParams.EXT2_FLOW?.match(/app([a-zA-Z0-9]+)/)?.[1]
                    )}
                    onChange={(e, data) => {
                        if(data.value === null){
                            props.setCallCenterData((prevState) => ({
                                ...prevState,
                                reportConfiguration: {
                                    ...prevState.reportConfiguration,
                                    pbiParams: {
                                        ...prevState.reportConfiguration.pbiParams,
                                        ["EXT2_FLOW"]: null,
                                    },
                                },
                            }));
                        }else{
                        let EXTENDED_FLOW_2 = "flow" + authContext._user.profile.tid.split("-")[0] + "app" + data.value.value
                            .replace(/-/g, "")
                        props.setCallCenterData((prevState) => ({
                            ...prevState,
                            reportConfiguration: {
                                ...prevState.reportConfiguration,
                                pbiParams: {
                                    ...prevState.reportConfiguration.pbiParams,
                                    ["EXT2_FLOW"]: EXTENDED_FLOW_2,
                                },
                            },
                        }));
                        }
                    }}
                    checkable
                />
            </div>
            <div className="report-params-div">
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'EXTENDED_FLOW' }) + " 3"}
                    />
                    <Popup
                    className="report-info-pop-up"
                        content={`${intl.formatMessage({ id: "EXTENDED_FLOW_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Dropdown
                    className="from-item"
                    items={scopeCallFlows}
                    clearable
                    value={scopeCallFlows.find(
                        (item) =>
                            item.value.replace(/-/g, "") ===
                            props.reportConfiguration.pbiParams.EXT3_FLOW?.match(/app([a-zA-Z0-9]+)/)?.[1]
                    )}
                    onChange={(e, data) => {
                        if(data.value === null){
                            props.setCallCenterData((prevState) => ({
                                ...prevState,
                                reportConfiguration: {
                                    ...prevState.reportConfiguration,
                                    pbiParams: {
                                        ...prevState.reportConfiguration.pbiParams,
                                        ["EXT3_FLOW"]: null,
                                    },
                                },
                            }));
                        }else{
                        let EXTENDED_FLOW_3 = "flow" + authContext._user.profile.tid.split("-")[0] + "app" + data.value.value
                            .replace(/-/g, "")
                        props.setCallCenterData((prevState) => ({
                            ...prevState,
                            reportConfiguration: {
                                ...prevState.reportConfiguration,
                                pbiParams: {
                                    ...prevState.reportConfiguration.pbiParams,
                                    ["EXT3_FLOW"]: EXTENDED_FLOW_3,
                                },
                            },
                        }));
                        }
                    }}
                    checkable
                />
            </div>
            <div className="report-params-div">
                <div className="report-label-div">
                    <Header
                        as="h5"
                        className="report-label"
                        content={intl.formatMessage({ id: 'EXTENDED_FLOW' }) + " 4"}
                    />
                    <Popup
                        content={`${intl.formatMessage({ id: "EXTENDED_FLOW_INFO" })}`}
                        trigger={
                            <Button
                                icon={<InfoIcon />}
                                iconOnly
                                text
                            />
                        }
                    />
                </div>
                <Dropdown
                    className="from-item"
                    items={scopeCallFlows}
                    clearable
                    value={scopeCallFlows.find(
                        (item) =>
                            item.value.replace(/-/g, "") ===
                            props.reportConfiguration.pbiParams.EXT4_FLOW?.match(/app([a-zA-Z0-9]+)/)?.[1]
                    )}
                    onChange={(e, data) => {
                        if(data.value === null){
                            props.setCallCenterData((prevState) => ({
                                ...prevState,
                                reportConfiguration: {
                                    ...prevState.reportConfiguration,
                                    pbiParams: {
                                        ...prevState.reportConfiguration.pbiParams,
                                        ["EXT4_FLOW"]: null,
                                    },
                                },
                            }));
                        }else{
                        let EXTENDED_FLOW_4 = "flow" + authContext._user.profile.tid.split("-")[0] + "app" + data.value.value
                            .replace(/-/g, "")
                        props.setCallCenterData((prevState) => ({
                            ...prevState,
                            reportConfiguration: {
                                ...prevState.reportConfiguration,
                                pbiParams: {
                                    ...prevState.reportConfiguration.pbiParams,
                                    ["EXT4_FLOW"]: EXTENDED_FLOW_4,
                                },
                            },
                        }));
                        }
                    }}
                    checkable
                />
            </div>

            < Header as="h4" content={intl.formatMessage({ id: "TEMPLATE" })} />
            <Dropdown
                className="from-item"
                items={reportTemplates}
                defaultValue={reportTemplates[0]}
                onChange={(e, data) => {
                    console.log("Template changed:", data.value);
                }}
                checkable
            />
        </div>
    );
};

export default ReportComponent;

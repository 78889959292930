import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.ENGLISH]: {
    langue: "en",
    globalSettings: "Global settings",
    Administrators: "Administrators",
    queues: "Queues",
    chat_queues: "Chat queues",
    call_queue: "Call queue",
    Flows: "Flows",
    call_flow: "Call flow",
    chat_flow: "Chat flow",
    agents: "Agents",
    media: "Media",
    licences: "Licences",
    licence: "licence",
    "Heedify admin portal": "Heedify admin portal",
    "Heedify service application consent":
      "Heedify service application consent",
    "Heedify Call Center and Receptionist Console Application":
      "Heedify Call Center and Receptionist Console Application",
    "Heedify Presence Application": "Heedify Presence Application",
    "Consent Permissons": "Consent Permissons",
    "Technical Teams account": "Technical Teams account",
    "This technical teams account is used by Heedify BackEnd services to track presence status of Agents":
      "This technical teams account is used by Heedify BackEnd services to track presence status of Agents",
    "Please note that we dont support MFA (Multi-Fator Authentication) for this account":
      "Please note that we dont support MFA (Multi-Fator Authentication) for this account",
    "The defined technical user is": "The defined technical user is : ",
    "The group id is": "The group id is",
    Submit: "Submit",
    userName: "User name",
    password: "Password",
    "Global Administrators": "Global Administrators",
    "Global Administrators have all permissions to create, delete, list and update all Heedify services":
      "Global Administrators have all permissions to create, delete, list and update all Heedify services",
    "start-typing-queue-name": "Start typing queue name",
    "start-typing-name": "Start typing name",
    "we-couldn't-find-any-matches": "We couldn't find any matches.",
    "start-typing": "Start typing",
    confirmation: "Confirmation",
    "start-typing-a-name": "Start typing a name",
    "are-you-sure-to-remove": "Are you sure to remove ",
    yes: "Yes",
    no: "No",
    "chat-queues-list": "Chat queues list",
    add: "Add",
    name: "Name",
    type: "Type",
    language: "Language",
    "max-wait-time": "Max wait time (Seconds)",
    "number-of-agents": "Agent number",
    action: "Action",
    "remove-chat-queue": "Remove chat queue",
    "edit-chat-queue": "Edit chat flow",
    save: "Save",
    "queue-name": "Queue Name",
    "type-here...": "Type here...",
    "text-on-hold": "Text on hold",
    "Max wait time (seconds)": "Max wait time (seconds)",
    "agents-list": "Agents list",
    "queues-list": "Queues list",
    "remove-queue": "Remove queue",
    "edit-queue": "Edit queue",
    "hunt-method": "Agent hunt strategy",
    "number-of-agents": "Number of agents",
    "queue-size": "Queue size",
    "announce-position-in-queue": "Announce position in queue",
    "Longest-Idle": "Longest Idle",
    "music-on-hold": "Music on hold",
    "max-calls-in-queue": "Max calls in queue",
    "VIP-priority": "VIP priority",
    "SLA-threshold": "SLA Threshold",
    "queue-fallback": "Queue fallback",
    "transfert- type": "Transfert type",
    "transfert-type": "Transfert type",
    disconnect: "Disconnect",
    queue: "Queue",
    "transfert-to": "Transfert to",
    attendant: "Attendant",
    serial: "Serial",
    roundRobin: "RoundRobin",
    "queue-configuration": "Queue configuration",
    "agent-configuration": "Agent configuration",
    "service-integration": "Service integration",
    phone: "Phone",
    user: "User",
    "phone-number": "Phone number",
    "select-type": "Select type",
    "api-key": "API key",
    "URL-parameters": "URL parameters",
    "add-new-parametre": "Add new parametre",
    "show-details": "Show details",
    "new-parametre": "New parametre",
    "delete-parametre": "Delete parametre",
    label: "Label",
    "parametre-name": "Parametre name",
    value: "Value",
    "select-value": "Select value",
    "medias-list": "Medias list",
    "remove-media": "Remove media",
    "add-media": "Add media",
    "Filename:": "File name : ",
    "Filetype:": "Filetype : ",
    "Size in bytes:": "Size in bytes : ",
    "lastModifiedDate:": "Last Modified Date : ",
    "The audio file should be a .wav": "The audio file should be a .wav",
    "The audio content in the .wav file should be: 16-bits per sample, little-endian, 16000 sampling rate, mono/single-channel.":
      "The audio content in the .wav file should be: 16-bits per sample, little-endian, 16000 sampling rate, mono/single-channel.",
    licences: "Licences",
    "licence-type": "Licence type",
    quantity: "Quantity",
    used: "Used",
    scope: "Entity",
    "licence-level": "Licence level",
    "remove-agent": "Remove agent",
    "edit-agent": "Edit agent",
    "add-agent": "Add agent",
    "search-agent": "Agent search",
    "outbound-call": "Outbound call",
    USER_PRINCIPAL_CALLFLOW: "User pincipal callflow",
    "remove-call-flow": "Remove callflow",
    "edit-call-flow": "Edit callflow",
    "resource-account": "Resource account",
    "time-zone": "TimeZone",
    "Emergency Message": "Emergency Message",
    "Welcome Message": "Welcome Message",
    "Flash Message": "Flash Message",
    "Custom Message": "Custom Message",
    "emergency-message": "Emergency Message",
    "welcome-message": "Welcome Message",
    "flash-message": "Flash Message",
    "custom-message": "Custom Message",
    "call-flow-list": "Callflow list",
    greeting: "Greeting",
    Holidays: "Holidays",
    routing: "Routing",
    "functional-admins": "Functional admins",
    recording: "Recording",
    dataLocation: "Data Location",
    text: "Text",
    audio: "Audio",
    "holiday-message": "Holiday message",
    "out-of-business-hours-message": "Out of business hours message",
    "select-audio": "Select audio",
    "generate-audio": "Generate audio",
    "transform-to-audio": "Transform to audio",
    weekend: "Weekend",
    "holidays-fallback": "Holidays fallback",
    "select-holidays": "Select holidays",
    "add-new-holidays": "Add new holidays",
    "start-date": "Start date",
    "end-date": "End date",
    "select-weekend-days": "Select weekend days",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
    "open-hours": "Open Hours",
    "Open Hours": "Open Hours",
    "open-hours-fallback": "Open hours fallback",
    "start-time": "Start time",
    "end-time": "End time",
    "disable-recording": "Call recording",
    "delete-routing": "Delete routing",
    "routing-type": "Routing type",
    "prompt-name": "Prompt name",
    "text-to-speech": "Text to speech",
    next: "Next",
    "choise-name": "Choise name",
    "IVR-name": "IVR name",
    "add-new-dial-key": "Add new dial key",
    "select-dial-key": "Select dial key",
    "action-type": "Action type",
    delete: "Delete",
    "select-routing": "Select routing",
    "redirect-to": "Redirect to",
    matched: "matched",
    "Invalid regular expression !": "Invalid regular expression !",
    "You can not verify an empty input !":
      "You can not verify an empty input !",
    "queue-name": "Queue name",
    "if-not-matched": "If not matched",
    "if-matched": "If matched",
    "add-new-routing": "Add new routing",
    ENABLE_SURVEY: "Enable customer satisfaction survey",
    "The routing name should not contain a space ' ' ":
      "The routing name should not contain a space ' ' ",
    "The IVR name should not contain a space ' ' ":
      "The IVR name should not contain a space ' ' ",
    result: "Result...",
    test: "Test",
    "chat-flow-list": "Chat flow list",
    "remove-chat-flow": "Remove chat flow",
    "edit-chat-flow": "Edit call flow",
    "add-new-chat-flow": "Add new chat flow",
    "bot-key": "Bot key",
    "bot-id": "Bot id",
    "chat-flow-id": "Chat flow id",
    "chat-flow-name": "Chat flow name",
    "chat-flow-type": "Chat flow type",
    "card-name": "Card name",
    "add-postBack": "Add postBack",
    "Start time: You need respect this form hh:mm !":
      "Start time: You need respect this form hh:mm !",
    "End time: You need respect this form hh:mm !":
      "End time: You need respect this form hh:mm !",
    "agent-name": "Agent name",
    skill: "Skill",
    "last-digit": "Wait until customer enters #",
    "digits-length": "Expected digits number",
    AVAILABLE_ATTENDANT: "Attendant",
    AVAILABLE_CALLCENTER: "Call center",
    ATTENDANT: "Attendant",
    CALLCENTER: "Call center",
    PREMIUM: "Premium",
    CX_ATTENDANT: "CX Attendant",
    CX_ESSENTIALS: "CX Essentials",
    CX_EXPERIENCE: "CX Experience",
    CX_ELEVATE: "CX Elevate",
    "save-agent": "save agent",
    NULL: "null",
    cancel: "Cancel",
    greeting_home_page: "Welcome to Heedify admin portal",
    home_page_message: " welcome",
    "Good-morning": "Good morning",
    "Good-afternoon": "Good afternoon",
    "Good-evening": "Good evening",
    calls: "Calls",
    chat: "Chat",
    "audio-transcribe": "Transcription Web service (Azure Speech) ",
    "chat-queue": "Channels",
    "scopes-managemant": "Organization Entities",
    "scopes-introduction":
      "Organization entities acts as containers for Heedify admin portal configurations, you can for example create entities to present your company subsidiaries",
    "scope-name": "Entity name : ",
    "admin-list": "Admin list",
    close: "Close",
    scope: "Entity",
    edit: "Edit",
    Error: "Error",
    "To add an agent you must fill in all the fields":
      "To add an agent you must fill in all the fields !",
    "link-to-callflow": "Link to call flow",
    voice: "Voice",
    customerChoice: "Customer choice",
    "announce-estimated-wait-time": "Announce estimated wait time",
    longestIdle: "Longest Idle",
    waitTime_interval_hint:
      "Wait time is announced according to defined interval. Example: intervals are [3, 5, 10] if a call expected wait time is 4 min customer will hear 'your expected wait time is less than 5 min'",
    authorizedActivityValues: "Authorized activity values",
    "worning-time-interval-input":
      "The value shoud be > 0 and does not exist in list",
    fallbackIfNoAgentConnected: "Fall back if no agent connected",
    playMessageBeforeQueueFallBack: "Play message before queue fallBack",
    "add-DTMF-without-copy": "Add",
    "DTMF-EXISTANCE-ERROR-MESSAGE": "This DTMF exists in the list.",
    "DTMF-EMPTY-ERROR-MESSAGE": "Please enter a DTMF",
    "copy-from-other-routing": "Copy",
    "add-new-DTMF": "Add new DTMF",
    "DTMF-NUMBER-ERROR-MESSAGE": "The DTMF must be a number.",
    form: "Form",
    "add-item": "Add new input",
    placeholder: "Placeholder",
    date: "Date",
    "Azure-ad-agent-provisioning-title": "Azure AD provisioning security group",
    "Azure-ad-agent-provisioning-discription":
      "Azure ad agent provisioning discription",
    "label-Provisioning-group": "Label Provisioning group",
    agents2: "Agent",
    "Failed to add agent": "Failed to add agent",
    groups: "Groups",
    "groups-management": "Agents groups",
    "groups-introduction":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ",
    "agent-list": "Agent list",
    "group-name": "Group name",
    supervisors: "Supervisors",
    email: "Email",
    supervisor: "Supervisor",
    available: "Available",
    total: "Total",
    "agent-queues": "Agent queues",
    agent_Queues_hint:
      "    When agent becomes available, queues will be notified in the following order",
    Survey: "Survey",
    timeout: "Timeout",
    ADD_NEW_QUEUESTION: "Add new queuestion",
    VALUE_RANGE_INF: "Range inf",
    VALUE_RANGE_SUP: "Range sup",
    YES_OR_NO: "Yes or No",
    RATING: "Rating",
    NUMERICAL: "Numerical",
    ANNOUCEMENT: "Annoucement",
    text2speech: "Text2speech",
    CALLFLOW_NUMERICAL_CASE_ERRORMESSAGE:
      "The value of range inf must be less than that of range sup.",
    ADVANCED: "Advanced",
    AGENT_ACTIVE_RECORDING: "Enable call recording",
    ENABLE_ALL_CALLS_REPORTING: "Include agent P2P calls on reports",
    CALL_RECORDING_SCOPE: "Call recording scope",
    AGENT_ACTIVE_ALL_CALLS_RECORDING: "Enable agent all calls recording ",
    RECORDING_WEBHOOK: "Recording webhook",
    ALL: "All calls",
    FLOW_CALLS_ONLY: "Only flow calls",
    ENABLE_SURVEY: "Active the survey",
    RANGE_VALUES: "Range values",
    QUESTION_TEXT: "Question",
    NO_DIGIT: "Number of 'No' expected",
    YES_DIGIT: "Digit to be interpreted as 'Yes'",
    QUESTION_ORDRE: 'Question order',
    ORDER_ERROR_MESSAGE: "The order must not be empty or used in another question.",
    SERVY_CLOSER_MESSAGE: "Closing message",
    GOOD_BYE_MESSAGE: "Good bye message",
    HOVER_DELETE_QUESTION_MESSAGE: "Delete question",
    HOVER_HIDE_DETAILS_BTN_MESSAGE: 'Show details',
    HOVER_SHOW_DETAILS_BTN_MESSAGE: 'Hide details',
    SECONDES: "Seconds",
    ADD_QUESTION_ERROR_MESSAGE: "The field cannot be empty or contain spaces ' ' ",
    ADD_ROUTING_ERROR_MESSAGE: "The field cannot be empty or contain spaces ' ' ",
    YES_DIGIT_ERROR_MESSAGE: "The field must contain a number between 1 and 9 or '*' or '@'",
    WEB_PAGE: 'Web page',
    NONE: 'None',
    IN_AGENT_CONSOLE: 'Open in agent console',
    OPEN_NEW_BROWSER_TAB: 'Open in a browser tab',
    WEB_HOOK: 'Webhook',
    CRM: 'CRM',
    SUPERVISION_MODE: 'Supervision mode',
    PERMANENT_IN_DASHBORD: 'Supervise all',
    SUPERVISE_PSTN_ONLY: 'Supervise PSTN only',
    DEFAULT: 'Default',
    PSTN: 'PSTN',
    P2P: 'P2P',
    WRAP_UP_TIME: 'Wrap up Time',
    SUPERVISOR_LISTEN_TO_P2P_CALLS: 'P2P call listening',
    SUPERVISOR_LISTEN_TO_CALLS: 'Call listening',

    Report: "Reports",
    "Report parameters": "Report parameters",
    TEMPLATE: "Template",
    QUICK_DROP_THRESHOLD: "Quick drop threshold",
    QUICK_ANSWER_THRESHOLD: "Quick answer threshold",
    DELAYED_ANSWER_THRESHOLD: "Delayed answer threshold", // deprecated
    SA_THRESHOLD:"Standard answer threshold", 
    TIMEZONE: "Timezone",
    ABANDON_TIME_MAX: "Max Abdandon time",
    ABANDON_TIME_TARGET: "Target Abondon time", 
    TALKING_TIME_MAX: "Max Talking time",
    TALKING_TIME_TARGET: "Target Talking time",
    WAITING_TIME_MAX: "Max Waiting time",
    WAITING_TIME_TARGET: "Target Waiting time",
    THRESHOLD: "Thresholds",
    ABANDON_TIME: "Abandon time",
    TALKING_TIME: "Talking time",
    WAITING_TIME: "Waiting time",
    QD_THRESHOLD_INFO: "Quick Drop Threshold in seconds. This parameter defines the maximum time allowed before an dropped call is considered as quick an early drop.",
  QA_THRESHOLD_INFO: "Quick Answer Threshold in seconds. This parameter defines the maximum acceptable ammount of time a call is answered to consider the response as quick.",
  SA_THRESHOLD_INFO: "Standard Answer Threshold in seconds. This parameter defines the maximum acceptable ammount of time a call is answered to consider the response as standard.",
  DA_THRESHOLD_INFO: "Delayed Answer Threshold in seconds. This parameter defines the minimum ammount of time a call is answered to consider the response as delayed.",
  ABANDON_TIME_MAX_INFO: "Maximum Abandon Time in seconds. This parameter defines the limit value on a gaugue chart.",
  ABANDON_TIME_TARGET_INFO: "Target Abandon Time in seconds. This parameter defines the target marker on a gauge chart.",
  TALKING_TIME_MAX_INFO: "Maximum Talking Time in seconds. This parameter defines the limit value on a gaugue chart.",
  TALKING_TIME_TARGET_INFO: "Target Talking Time in seconds. This parameter defines the target marker on a gauge chart.",
  WAITING_TIME_MAX_INFO: "Maximum Waiting Time in seconds. This parameter defines the limit value on a gaugue chart.",
  WAITING_TIME_TARGET_INFO: "Target Waiting Time in seconds. This parameter defines the target marker on a gauge chart.",
   EXTENDED_FLOW:"Additional flows",
   EXTENDED_FLOW_INFO: "Additional flows. Used to define additionals callflows name if a consildated report is needed"


  },
};

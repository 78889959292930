import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Dropdown,
  Loader,
  Flex,
  Checkbox,
  Input,
} from "@fluentui/react-northstar";
import { Tabs, Tab } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import SearchUser from "./searchUser";
import { authContext } from "../../auth";
import { confirmAlert } from "react-confirm-alert";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  addAgentUrl,
  updateAgentUrl,
  getAllLicences,
  selectAgentUrl,
} from "../../urls";
import Axios from "axios";
import { getToken } from "../../components/Graph";
import AgentQueues from "./AgentQueues";
import "./agentComponent.css";
const AgentComponent = (props) => {
  const params = useParams();
  const intl = useIntl();
  const history = useHistory();
  const [agent, setAgent] = useState({});
  const [showAdvencedDiv, setShowAdvencedDiv] = useState(false);
  const [scopeList, setScopeList] = useState({});
  const [userPrincipalCallFlow, setUserPrincipalCallFlow] = useState([]);
  const [licences, setLicences] = useState([]);
  useEffect(() => {
    if (props.scopeList) {
      setScopeList(Object.keys(props.scopeList));
      if (params.id === "newAgent") {
        let defaultScope = "default";
        if (!Object.keys(props.scopeList).includes(defaultScope)) {
          defaultScope = Object.keys(props.scopeList)[0];
        }
        setAgent({
          RowKey: "",
          id: "",
          displayName: "",
          email: "",
          scope: defaultScope,
          Licence: false,
          licenceLevel: "",
          outboundCalling: {
            applicationInstanceId: "",
            applicationInstanceDisplayName: "",
          },
          agentQueueIds: [],
          agentQueueDetails: {},
          enableCallRecording: false,
          callRecordingScope: "NULL",
          enableAllCallsReporting: false,
          recordingWebhook: null,
        });
      } else {
        getAgent(params.id);
      }
    }
  }, [props.scopeList]);
  useEffect(() => {
    if (props.callFlows) {
      setAgentCallFlows();
    }
  }, [agent, props.callFlows]);
  useEffect(() => {
    if (props.licences) {
      setAgentLicences();
    }
  }, [agent, props.licences]);
  useEffect(() => {
    let newAgent = agent;
    if (agent && !agent.enableCallRecording) {
      newAgent.enableCallRecording = false;
    }
    if (agent && !agent.callRecordingScope) {
      newAgent.callRecordingScope = "NULL";
    }
    if (agent && !agent.recordingWebhook) {
      newAgent.recordingWebhook = null;
    }
    if ((agent.licenceLevel && agent.licenceLevel !== "CX_ELEVATE" && agent.licenceLevel !== "CX_EXPERIENCE")) {
      newAgent.enableCallRecording = false;
      newAgent.callRecordingScope = "NULL";
      newAgent.recordingWebhook = null;
    }
    setAgent(newAgent);
  }, [agent])
  const getAgent = async (agentId) => {
    let url = selectAgentUrl(
      authContext._user.profile.tid,
      authContext._user.profile.oid,
      agentId
    );
    Axios.post(url, {}).then((result) => {
      setAgent(result.data);
    });
  };
  const addContact = (contact) => {
    if (contact.header && contact.id && contact.id && contact.email) {
      setAgent({
        ...agent,
        displayName: contact.header,
        id: contact.id,
        RowKey: contact.id,
        email: contact.email,
      });
    }
  };
  const setAgentCallFlows = () => {
    //for evry scope we get callflows and we update liste of callflows of agent (userPrincipalCallFlow)
    setUserPrincipalCallFlow([]);
    for (const [key, value] of Object.entries(props.callFlows)) {
      if (value.scope === agent.scope) {
        setUserPrincipalCallFlow((userPrincipalCallFlow) => [
          ...userPrincipalCallFlow,
          value.displayName,
        ]);
      }
    }
  };
  const setAgentLicences = () => {
    setLicences([]);
    let scopeLicences = props.licences[agent.scope];
    if (scopeLicences && Object.keys(scopeLicences).length > 0) {
      let licencesName = Object.keys(scopeLicences);
      licencesName = licencesName.filter(
        (key) =>
          !key.includes("PartitionKey") &&
          !key.includes("RowKey") &&
          !key.includes("AVAILABLE_") &&
          !key.includes("USED_")
      );
      for (const licence of licencesName) {
        if (scopeLicences["AVAILABLE_" + licence] > 0) {
          setLicences((licences) => [
            ...licences,
            intl.formatMessage({ id: licence }),
          ]);
        }
      }
    }
  };
  const addNewAgent = async () => {
    // validation of all inputs of agent
    if (
      Object.keys( agent.outboundCalling).length===0||
      agent.outboundCalling.applicationInstanceId === "" ||
      agent.outboundCalling.applicationInstanceDisplayName === "" ||
      agent.scope === "" ||
      agent.id === "" ||
      agent.licenceLevel === ""
    ) {
      confirmAlert({
        title: intl.formatMessage({ id: "Error" }),
        message: intl.formatMessage({
          id: "To add an agent you must fill in all the fields",
        }),
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      const url = addAgentUrl(
        authContext._user.profile.tid,
        authContext._user.profile.oid,
        agent.scope
      );
      if (
        (agent.id !== undefined && agent.id !== "") ||
        (agent.RowKey !== undefined && agent.RowKey !== "")
      ) {
        Axios.post(url, { agent: agent }).then((result) => {
          if (result.data === "Created") {
            getLicenceList();
            history.push(`/agent`);
          } else {
            confirmAlert({
              title: intl.formatMessage({ id: "Error" }),
              message: intl.formatMessage({ id: "Failed to add agent" }),
              buttons: [
                {
                  label: "Ok",
                },
              ],
            });
          }
        });
      }
    }
  };
  const getLicenceList = async () => {
    let scopeListName = Object.keys(props.scopeList);
    const promises = [];
    let Token = await getToken(authContext);
    for (let scope of scopeListName) {
      const url = getAllLicences(authContext._user.profile.tid, Token, scope);
      await Axios.get(url).then((Res) => {
        promises.push(Res.data);
      });
    }
    Promise.all(promises)
      .then((results) => {
        var stateNew = {};
        for (var result of results) {
          stateNew[result.RowKey] = result;
        }
        return stateNew;
      })
      .then((stateNew) => {
        props.setLicences(stateNew);
      });
  };
  const updateAgent = async () => {
    // validation of all inputs of agent
    if (
      Object.keys( agent.outboundCalling).length === 0 ||
      (agent.outboundCalling &&
        agent.outboundCalling.applicationInstanceId === "") ||
      (agent.outboundCalling &&
        agent.outboundCalling.applicationInstanceDisplayName === "") ||
      agent.scope === "" ||
      agent.id === "" ||
      agent.licenceLevel === ""
    ) {
      confirmAlert({
        title: intl.formatMessage({ id: "Error" }),
        message: intl.formatMessage({
          id: "To add an agent you must fill in all the fields",
        }),
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      const url = updateAgentUrl(
        authContext._user.profile.tid,
        authContext._user.profile.oid,
        agent.RowKey,
        agent.scope
      );
      if (
        (agent.id !== undefined && agent.id !== "") ||
        (agent.RowKey !== undefined && agent.RowKey !== "")
      ) {
        Axios.post(url, { agent: agent }).then((result) => {
          if (result.data === "Updated") {
            getLicenceList();
            history.push(`/agent`);
          }
        });
      }
    }
  };
  return (
    <>
      {Object.keys(agent).length > 0 ? (
        <div id="agent-div">
          <Button
            content={intl.formatMessage({ id: "save" })}
            primary
            onClick={() => {
              if (params.id === "newAgent") {
                addNewAgent();
              } else {
                updateAgent();
              }
            }}
            id="submit-button-agent"
          />

          <Tabs
            defaultActiveKey="agent configuration"
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="agent configuration"
              title={intl.formatMessage({ id: "agent-configuration" })}
            >
              <div className="agent-name-div">
                <Header
                  as="h4"
                  content={intl.formatMessage({ id: "agent-name" })}
                  className="label-form_queue"
                />
                <SearchUser
                  id="search-user"
                  searchactifProfils
                  displayName={agent.displayName}
                  addContact={addContact}
                  isUpdate={params.id !== "newAgent" ? true : false}
                />
              </div>
              <div className="agent-scope-div">
                <Header
                  as="h4"
                  content={intl.formatMessage({ id: "scope" })}
                  className="label-form_queue"
                />
                <Dropdown
                  items={scopeList}
                  value={agent.scope}
                  disabled={params.id !== "newAgent" ? true : false}
                  onChange={(e, data) => {
                    setAgent((agent) => ({
                      ...agent,
                      scope: data.value,
                      licenceLevel: "",
                      outboundCalling: {
                        applicationInstanceId: "",
                        applicationInstanceDisplayName: "",
                      },
                    }));
                  }}
                />
              </div>
              <div className="agent-Outbound-call-div">
                <Header
                  as="h4"
                  content={intl.formatMessage({
                    id: "USER_PRINCIPAL_CALLFLOW",
                  })}
                  className="label-form_queue"
                />
                <Dropdown
                  items={userPrincipalCallFlow}
                  value={agent.outboundCalling &&
                    agent.outboundCalling.applicationInstanceDisplayName
                    ? agent.outboundCalling.applicationInstanceDisplayName
                    : null}
                  onChange={(e, data) => {
                    for (const [key, value] of Object.entries(
                      props.callFlows
                    )) {
                      if (data.value === value.displayName) {
                        let selectedCallFlow = {
                          applicationInstanceId: value.RowKey,
                          applicationInstanceDisplayName: value.displayName,
                        };
                        //  delete agent.USER_PRINCIPAL_CALLFLOW;
                        setAgent({
                          ...agent,
                          outboundCalling: selectedCallFlow,
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className="agent-licence-div">
                <Header
                  as="h4"
                  content={intl.formatMessage({ id: "licence-level" })}
                  className="label-form_queue"
                />
                <Dropdown
                  items={licences}
                  value={
                    agent.licenceLevel
                      ? intl.formatMessage({ id: agent.licenceLevel })
                      : ""
                  }
                  onChange={(e, data) => {
                    for (const [key, value] of Object.entries(intl.messages)) {
                      let formatedName = data.value.split("(").shift();
                      if (value === formatedName) {
                        setAgent({ ...agent, licenceLevel: key });
                      }
                    }
                  }}
                />
              </div>
              {
                agent.licenceLevel && (agent.licenceLevel === "CX_ELEVATE" || agent.licenceLevel === "CX_EXPERIENCE") ?
                  <div className="advenced-div">
                    <Flex hAlign="start" vAlign="start" gap="gap.small">
                      {showAdvencedDiv === false ? (
                        <Button
                          className="show-hide-advenced-btns"
                          primary
                          size="smallest"
                          icon={<BsChevronUp />}
                          text
                          title="Show details"
                          onClick={() => {
                            setShowAdvencedDiv(true);
                          }}
                        />
                      ) : (
                        <Button
                          className="show-hide-advenced-btns"
                          primary
                          size="smallest"
                          icon={<BsChevronDown />}
                          text
                          title="Show details"
                          onClick={() => {
                            setShowAdvencedDiv(false);
                          }}
                        />
                      )}
                      <Header
                        className="title-start-routing"
                        content={intl.formatMessage({ id: "ADVANCED" })}
                      />
                    </Flex>
                    {showAdvencedDiv === false ? (
                      <div className="advenced-sub-div">
                        <Flex hAlign="start" vAlign="start" gap="gap.small">
                          <Header
                            as="h5"
                            content={intl.formatMessage({
                              id: "AGENT_ACTIVE_RECORDING",
                            })}
                          />
                          <Checkbox
                            className=" agent-advenced-checkbox"
                            checked={
                              agent.enableCallRecording
                                ? agent.enableCallRecording
                                : false
                            }
                            toggle
                            onClick={(e, data) => {
                              setAgent({
                                ...agent,
                                enableCallRecording: data.checked,
                                callRecordingScope: data.checked === false ? "NULL" : agent.callRecordingScope,
                              });
                            }}
                          />
                        </Flex>
                        <div style={{ marginTop: '17px' }}>
                          <Flex hAlign="start" vAlign="start" gap="gap.small" >
                            <Header
                              as="h5"
                              content={intl.formatMessage({
                                id: "ENABLE_ALL_CALLS_REPORTING",
                              })}
                            />
                            <Checkbox
                              className=" agent-advenced-checkbox"
                              checked={
                                agent.enableAllCallsReporting
                                  ? agent.enableAllCallsReporting
                                  : false
                              }
                              toggle
                              onClick={(e, data) => {
                                setAgent({
                                  ...agent,
                                  enableAllCallsReporting: data.checked,
                                });
                              }}
                            />
                          </Flex>
                        </div>
                        <div className="flex-advenced-div">
                          <Header
                            as="h5"
                            content={intl.formatMessage({ id: "CALL_RECORDING_SCOPE" })}
                          />
                          <Dropdown
                            className="child-title-call-flow"
                            value={agent.callRecordingScope && agent.callRecordingScope !== null ? intl.formatMessage({ id: agent.callRecordingScope, }) : intl.formatMessage({ id: "NULL" })}
                            onChange={(e, data) => {
                              setAgent({
                                ...agent,
                                callRecordingScope: data.value.key,
                              });
                            }}
                            items={[
                              {
                                key: "ALL",
                                header: intl.formatMessage({ id: "ALL" }),
                              },
                              {
                                key: "FLOW_CALLS_ONLY",
                                header: intl.formatMessage({
                                  id: "FLOW_CALLS_ONLY",
                                }),
                              },
                              {
                                key: "P2P",
                                header: intl.formatMessage({
                                  id: "P2P",
                                }),
                              },
                              {
                                key: "PSTN",
                                header: intl.formatMessage({
                                  id: "PSTN",
                                }),
                              }
                            ]}
                          />
                        </div>
                        <div className="flex-advenced-div">
                          <Header
                            as="h5"
                            content={intl.formatMessage({
                              id: "RECORDING_WEBHOOK",
                            })}
                          />
                          <Input
                            className="recordingWebhook-input"
                            value={
                              agent.recordingWebhook ? agent.recordingWebhook : null
                            }
                            onChange={(event, data) => {
                              setAgent({ ...agent, recordingWebhook: data.value });
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  :
                  null
              }
            </Tab>
            <Tab
              eventKey="agent queues"
              title={intl.formatMessage({ id: "agent-queues" })}
            >
              <Header
                as="p"
                content={intl.formatMessage({ id: "agent_Queues_hint" })}
                className="technical-teams-account"
              />
              <AgentQueues agent={agent} setAgent={setAgent} />
            </Tab>
          </Tabs>
        </div>
      ) : (
        <Loader label="Loading ..." />
      )
      }
    </>
  );
};
export default AgentComponent;

/* eslint-disable no-useless-computed-key */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CallCenterName from "../../components/CallCenterComponents/CallCenterName";
import TimeZone from "../../components/CallCenterComponents/TimeZone";
import FunctionAdmins from "../../components/CallCenterComponents/FunctionAdmins";
import Language from "../../components/CallCenterComponents/Language";
import { Tabs, Tab } from "react-bootstrap";
import {
  Input,
  Header,
  Divider,
  Button,
  Flex,
  Loader,
  List,
  Avatar,
  TrashCanIcon,
  AddIcon,
} from "@fluentui/react-northstar";
import AnnoucementComponent from "../../components/CallCenterComponents/AnnoucementComponent";
import ReportComponent from "../../components/CallCenterComponents/ReportComponent";
import Holidays from "../../components/CallCenterComponents/Holidays";
import Openhours from "../../components/CallCenterComponents/Openhours";
import Routing from "../../components/CallCenterComponents/Routing";
import SurveysComponent from "../../components/CallCenterComponents/SurveysComponent";
import DataLocation from "../../components/CallCenterComponents/DataLocation";
import CallflowScope from "../../components/CallCenterComponents/CallflowScope";
import Weekend from "../../components/CallCenterComponents/weekends";
import CallMaxWaitTime from "../../components/Queues/CallMaxWaitTimeout";
import Recording from "../../components/CallCenterComponents/Recording";
import { useParams } from "react-router-dom";
import Axios from "axios";
import data from "./autoAttendant.json";
import { authContext } from "../../auth";
import update from "immutability-helper";
import "../../css/CallFlow.css";
import {
  getAllAudioManagementUrl,
  selectCallFlowManagementUrl,
  addCallFlowManagementUrl,
  updateCallFlowManagementUrl,
} from "../../urls";
import { confirmAlert } from "react-confirm-alert";
import { getToken } from "../../components/Graph";
import { useIntl } from "react-intl";
const CallCenterContainer = (props) => {
  const params = useParams();
  const [callCenterData, setCallCenterData] = useState({});
  const [callRecordingReaders, setCallRecordingReaders] = useState([]);
  const [
    callRecordingReadersDetails,
    setCallRecordingReadersDetails,
  ] = useState({});
  const [functionalAdminsData, setFunctionalAdminsData] = useState([]);
  const [functionalAdminsDetails, setFunctionalAdminsDetails] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [status, setStatus] = useState(false);
  const [displayNewCallflow, setDisplayNewCallflow] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isNewRouting, setIsNewRouting] = useState(false);
  const [newRouting, setNewRouting] = useState("");
  const [routingErrorMessage, setRoutingErrorMessage] = useState("");
  const [IVRErrorMessage, setIVRErrorMessage] = useState("");
  const intl = useIntl();
  const getAudios = async () => {
    getToken(authContext).then(async (Token) => {
      const url = getAllAudioManagementUrl(
        authContext._user.profile.tid,
        Token
      );
      Axios.get(url).then((res) => {
        setAudioList(res.data);
      });
    });
  };
  const getCallFlowData = () => {
    if (params.id === "newCallFlow") {
      setCallCenterData(data);
      return;
    }
    const url = selectCallFlowManagementUrl(
      authContext._user.profile.tid,
      authContext._user.profile.oid,
      params.id,
      params.scope
    );
    Axios.get(url).then((res) => {
      let callFlow = res.data;
      if (!callFlow.survey) {
        callFlow.survey = { enabled: false, questions: {}, timeout: 0 };
      }
      setCallCenterData(callFlow);
      setStatus(true);
      if (res.data.callRecordingEnabled) {
        setCallRecordingEnabled(res.data.callRecordingEnabled);
      }
      /*
            if (res.data.callRecordingReaders) {
              setCallRecordingReaders((callRecordingReaders) => [
                res.data.callRecordingReaders
              ]);
            }
      */
      if (res.data.callRecordingReadersDetails) {
        setCallRecordingReadersDetails(res.data.callRecordingReadersDetails);
      }
      if (res.data.functionalAdminsDetails) {
        setFunctionalAdminsDetails(res.data.functionalAdminsDetails);
      }
      if (res.data.functionalAdmins) {
        setFunctionalAdminsData(res.data.functionalAdmins);
      }
    });
  };
  const verifPrams = (callFlow) => {
    let routings = callFlow.routing;
    Object.keys(routings).forEach(function (key, index) {
      if (routings[key].type === "ivr" && routings[key].displayName === "") {
        if (key === "0") {
          callFlow.routing[key.toString()].displayName = "start";
          setIvrName("start", key.toString());
        } else {
          callFlow.routing[key].displayName = key;
        }
      }
    });
    return callFlow;
  };
  useEffect(() => {
    if (props.scopeList && callCenterData.scope === "") {
      if (Object.keys(props.scopeList).includes("default")) {
        setCallCenterData((state) => {
          return { ...state, scope: "default" };
        });
      } else {
        setCallCenterData((state) => {
          return { ...state, scope: Object.keys(props.scopeList)[0] };
        });
      }
    }
  }, [callCenterData]);
  useEffect(() => {
    getAudios();
    getCallFlowData();
  }, []);
  let items = [];
  let itemsRecordingAgents = [];
  const sendData = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    let url = "";
    if (params.id === "newCallFlow") {
      url = addCallFlowManagementUrl(
        authContext._user.profile.tid,
        authContext._user.profile.oid,
        callCenterData.scope
      );
    } else {
      url = updateCallFlowManagementUrl(
        authContext._user.profile.tid,
        authContext._user.profile.oid,
        params.id,
        callCenterData.scope
      );
    }
    let callFlow = await verifPrams(callCenterData);
    Axios.post(url, { callFlow: callFlow }).then((res) => {
      setIsLoading(false);
      setIsDisabled(false);
      props.history.push("/call-flow");
    });
  };
  const getTimeZoneData = (elm, val) => {
    setCallCenterData((state) => {
      return { ...state, timeZone: val.value || "" };
    });
  };
  const getDataLocation = (elm, val) => {
    setCallCenterData((state) => {
      return { ...state, dataLocation: val.value || "" };
    });
  };
  const getLanguageData = (elm, val) => {
    setCallCenterData((state) => {
      return { ...state, language: val.value || "" };
    });
  };
  const setScope = (scope) => {
    setCallCenterData((state) => {
      return { ...state, scope: scope };
    });
  };
  const getFunctionAdminsData = (val) => {
    if (!functionalAdminsData.includes(val.id)) {
      setFunctionalAdminsData((functionalAdminsData) => [
        ...functionalAdminsData,
        val.id,
      ]);

      let newfunctionalAdminsDetails = { [val.id]: val.displayName };
      setFunctionalAdminsDetails((functionalAdminsDetails) => [
        ...functionalAdminsDetails,
        newfunctionalAdminsDetails,
      ]);
      setCallCenterData((state) => {
        return {
          ...state,
          functionalAdmins: [...functionalAdminsData, val.id],
        };
      });
      setCallCenterData((state) => {
        return {
          ...state,
          functionalAdminsDetails: [
            ...functionalAdminsDetails,
            newfunctionalAdminsDetails,
          ],
        };
      });
    }
  };
  const setCallRecordingEnabled = (status) => {
    setCallCenterData((state) => {
      return {
        ...state,
        callRecordingEnabled: status,
      };
    });
  };
  const setAudioTranscribeEnabled = (status) => {
    setCallCenterData((state) => {
      return {
        ...state,
        audioTranscribeEnabled: status,
      };
    });
  };
  function setRecordingData(val) {
    if (!callRecordingReaders.includes(val.id)) {
      setCallRecordingReaders([...callRecordingReaders, val.id]);
      setCallRecordingReadersDetails((callRecordingReadersDetails) => {
        return { ...callRecordingReadersDetails, [val.id]: val.displayName };
      });
      setCallCenterData((state) => {
        return {
          ...state,
          callRecordingReaders: [...callRecordingReaders, val.id],
        };
      });
      setCallCenterData((state) => {
        return {
          ...state,
          callRecordingReadersDetails: {
            ...callRecordingReadersDetails,
            [val.id]: val.displayName,
          },
        };
      });
    }
  }
  function setAudioTranscribeData(val) {
    setCallCenterData((state) => {
      return {
        ...state,
        audioTranscribeDetails: val,
      };
    });
  }
  const getAnnoucementData = (elm, val, itemToUpdate, annoucementName) => {
    switch (itemToUpdate) {
      case "enable":
        setCallCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { enabled: { $set: val } } },
          });
        });
        break;
      case "radio":
        setCallCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { type: { $set: val } } },
          });
        });

        break;
      case "textToSpeechLanguage":
        setCallCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { language: { $set: val } } },
          });
        });
        break;
      case "textToSpeechText":
        setCallCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { text: { $set: val } } },
          });
        });
        break;

      case "audio":
        setCallCenterData((state) => {
          return update(state, {
            greeting: {
              [annoucementName]: {
                audioFileUrl: {
                  $set: val[0]?.url || decodeURIComponent(val.url) || null,
                },
              },
            },
          });
        });
        break;
      default:
    }
  };
  const getHolidaysData = (elm, val, itemToUpdate, annoucementName) => {
    switch (itemToUpdate) {
      case "enable":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              [annoucementName]: { audio: { enabled: { $set: val } } },
            },
          });
        });
        break;
      case "radio":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: { [annoucementName]: { audio: { type: { $set: val } } } },
          });
        });
        break;
      case "textToSpeechLanguage":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              [annoucementName]: { audio: { language: { $set: val } } },
            },
          });
        });
        break;
      case "textToSpeechText":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: { [annoucementName]: { audio: { text: { $set: val } } } },
          });
        });
        break;
      case "audio":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              [annoucementName]: {
                audio: {
                  audioFileUrl: { $set: decodeURIComponent(val?.url) || null },
                },
              },
            },
          });
        });
        break;
      case "daysOff":
        setCallCenterData((state) => {
          const valinterm = JSON.parse(JSON.stringify(val));
          const valFormatted = valinterm.map((element) => {
            delete element.id;
            return element;
          });
          return update(state, {
            deskOpen: {
              [annoucementName]: { daysOff: { $set: valFormatted || null } },
            },
          });
        });
        break;
      case "weekends":
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              [annoucementName]: { weekendDays: { $set: val || null } },
            },
          });
        });
        break;
      default:
    }
  };
  const getOpenHoursData = (elm, val, weekDayNbr, dayPart, position) => {
    setCallCenterData((state) => {
      return update(state, {
        deskOpen: {
          openHours: {
            [weekDayNbr]: { [dayPart]: { [position]: { $set: val.value } } },
          },
        },
      });
    });
  };
  const getRoutingRadio = (val, itemToUpdate, info, routingName) => {
    switch (itemToUpdate) {
      case "transfer to phone":
        let transferToNumberObject = {
          type: "transfer",
          targetId: val,
          targetType: "phone",
        };
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                options: { [info]: { $set: transferToNumberObject } },
              },
            },
          });
        });
        break;
      case "routing":
        let trasferIvrToRouting = {
          type: "routing",
          routingName: val === "Start" ? "0" : val,
        };
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                options: { [info]: { $set: trasferIvrToRouting } },
              },
            },
          });
        });
        break;
      case "ivrTransfer":
        let ivrTransferObject = {
          type: "transfer",
          targetId: val.key,
          targetType: "user",
          userDisplayName: val.header,
        };
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                options: { [info]: { $set: ivrTransferObject } },
              },
            },
          });
        });
        break;
      case "ivrQueue":
        let ivrQueueobject = {
          type: "queue",
          queueId: val.key,
          queueDisplayName: val.header,
        };
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: { options: { [info]: { $set: ivrQueueobject } } },
            },
          });
        });
        break;
      case "ivr":
        setCallCenterData((state) => {
          return update(state, { routing: { [routingName]: { options: {} } } });
        });

        break;
      case "radio":
        if (val === "prompt") {
          let prompt = { type: "" };
          setCallCenterData((state) => {
            return update(state, {
              routing: {
                [routingName]: { type: { $set: val }, next: { $set: prompt } },
              },
            });
          });
        } else {
          setCallCenterData((state) => {
            return update(state, {
              routing: { [routingName]: { type: { $set: val } } },
            });
          });
        }

        break;
      case "queue":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                enabled: { $set: true },
                type: { $set: "queue" },
                queueDisplayName: { $set: val.header },
                queueId: { $set: val.key },
              },
            },
          });
        });
        setCallCenterData((state) => {
          return update(state, {
            routing: { [routingName]: { options: { $set: {} } } },
          });
        });
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: { displayName: { $set: "" } },
            },
          });
        });
        break;
      case "customerService":
        setCallCenterData((state) => {
          return {
            ...state,
            displayName: val?.header || "",
            userPrincipalName: val.userprincipalname,
          };
        });
        setCallCenterData((state) => {
          if (state.displayName !== "") {
            setDisplayNewCallflow("show");
          } else {
            setDisplayNewCallflow("hide");
          }
          return {
            ...state,
            id: val?.key || "",
          };
        });
        setCallCenterData((state) => {
          return {
            ...state,
            userPrincipalName: val?.userPrincipalName,
            userPrincipalName: val.userprincipalname,
          };
        });
        break;
      case "promptQueue":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "queue",
                    queueDisplayName: val.header,
                    queueId: val.key,
                  },
                },
              },
            },
          });
        });
        break;
      case "prompt transfer to user":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "transfer",
                    userDisplayName: val.header,
                    targetId: val.key,
                    targetType: "user",
                  },
                },
              },
            },
          });
        });
        break;
      case "promptDisconnect":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "disconnect",
                  },
                },
              },
            },
          });
        });
        break;
      case "prompt transfer to phone":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "transfer",
                    targetId: val,
                    targetType: "phone",
                  },
                },
              },
            },
          });
        });
        break;
      case "promptRouting":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "routing",
                    routingName: val === "Start" ? "0" : val,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnUnMatchedRegexpQueue":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onUnMatched: {
                  $set: {
                    type: "queue",
                    queueDisplayName: val.header,
                    queueId: val.key,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnMatchedRegexpQueue":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onMatched: {
                  $set: {
                    type: "queue",
                    queueDisplayName: val.header,
                    queueId: val.key,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnMatched transfer to user":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onMatched: {
                  $set: {
                    type: "transfer",
                    userDisplayName: val.header,
                    targetType: "user",
                    targetId: val.key,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnUnMatched transfer to user":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onUnMatched: {
                  $set: {
                    type: "transfer",
                    userDisplayName: val.header,
                    targetId: val.key,
                    targetType: "user",
                  },
                },
              },
            },
          });
        });
        break;
      case "OnMatchedDisconnect":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onMatched: {
                  $set: {
                    type: "disconnect",
                  },
                },
              },
            },
          });
        });
        break;
      case "OnUnMatchedDisconnect":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onUnMatched: {
                  $set: {
                    type: "disconnect",
                  },
                },
              },
            },
          });
        });
        break;
      case "OnUnMatchedRouting":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onUnMatched: {
                  $set: {
                    type: "routing",
                    routingName: val === "Start" ? "0" : val,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnMatchedRouting":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onMatched: {
                  $set: {
                    type: "routing",
                    routingName: val === "Start" ? "0" : val,
                  },
                },
              },
            },
          });
        });
        break;
      case "OnMatched transfer to phone":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onMatched: {
                  $set: {
                    type: "transfer",
                    targetId: val,
                    targetType: "phone",
                  },
                },
              },
            },
          });
        });
        break;
      case "OnUnMatched transfer to phone":
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                onUnMatched: {
                  $set: {
                    type: "transfer",
                    targetId: val,
                    targetType: "phone",
                  },
                },
              },
            },
          });
        });
        break;
      default:
    }
  };
  const setSelectedOverflowType = (val, itemToUpdate) => {
    if (itemToUpdate === "holidays") {
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            holidays: { holidaysAction: { type: { $set: val } } },
          },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            openHours: { openHoursAction: { type: { $set: val } } },
          },
        });
      });
    }
  };
  const setSelectedUserTransfertType = (val, itemToUpdate) => {
    if (itemToUpdate === "holidays") {
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            holidays: { holidaysAction: { targetType: { $set: val } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            holidays: { holidaysAction: { targetId: { $set: "" } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            holidays: { holidaysAction: { queueDisplayName: { $set: "" } } },
          },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            openHours: { openHoursAction: { targetType: { $set: val } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            openHours: { openHoursAction: { targetId: { $set: "" } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          deskOpen: {
            openHours: { openHoursAction: { queueDisplayName: { $set: "" } } },
          },
        });
      });
    }
  };
  const setSelectedUserTransfertId = (val, itemToUpdate) => {
    if (itemToUpdate) {
      if (itemToUpdate === "holidays") {
        setCallCenterData((state) => {
          let idToUpdate = "targetId";
          if (state.deskOpen.holidays.holidaysAction.targetType === "queue") {
            idToUpdate = "queueId";
          }
          return update(state, {
            deskOpen: {
              holidays: { holidaysAction: { [idToUpdate]: { $set: val.id } } },
            },
          });
        });
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              holidays: {
                holidaysAction: { queueDisplayName: { $set: val.header } },
              },
            },
          });
        });
      } else {
        setCallCenterData((state) => {
          let idToUpdate = "targetId";
          if (state.deskOpen.holidays.holidaysAction.targetType === "queue") {
            idToUpdate = "queueId";
          }
          return update(state, {
            deskOpen: {
              openHours: {
                openHoursAction: { [idToUpdate]: { $set: val.id } },
              },
            },
          });
        });
        setCallCenterData((state) => {
          return update(state, {
            deskOpen: {
              openHours: {
                openHoursAction: { queueDisplayName: { $set: val.header } },
              },
            },
          });
        });
      }
    }
  };
  useEffect(() => {
    if (params.id === "newCallFlow" || callCenterData.displayName === "") {
      setDisplayNewCallflow("hide");
    } else {
      setDisplayNewCallflow("show");
    }
  }, []);
  const removeAdminUser = (key) => {
    let newFunctionalAdminsData = callCenterData.functionalAdmins.filter(
      (data) => {
        return data !== key;
      }
    );
    setFunctionalAdminsData(newFunctionalAdminsData);

    let newfunctionalAdminsDetails = callCenterData.functionalAdminsDetails.filter(
      (data) => {
        return Object.keys(data)[0] !== key;
      }
    );
    setFunctionalAdminsDetails(newfunctionalAdminsDetails);

    setCallCenterData((state) => {
      const newState = state.functionalAdminsDetails.filter((data) => {
        return Object.keys(data)[0] !== key;
      });
      return { ...state, functionalAdminsDetails: newState };
    });

    setCallCenterData((state) => {
      const newState = state.functionalAdmins.filter((data) => {
        return data !== key;
      });
      return { ...state, functionalAdmins: newState };
    });
  };
  const confirmeRemoveAdminUser = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message:
        intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + "!",
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeAdminUser(id),
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  const getSelectedIvrData = (data, routingName) => {
    const getAudioInfo = {
      type: "Audiofile",
      language: "",
      text: "",
      audioFileUrl: data.url,
    };
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            audio: {
              audioFileUrl: { $set: getAudioInfo.audioFileUrl },
              language: { $set: getAudioInfo.language },
              type: { $set: getAudioInfo.type },
              text: { $set: getAudioInfo.text },
            },
          },
        },
      });
    });
  };
  const getSelectedPromptData = (data, routingName) => {
    const getAudioInfo = {
      type: "Audiofile",
      language: "",
      text: "",
      audioFileUrl: data.url,
    };
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            audio: {
              audioFileUrl: { $set: getAudioInfo.audioFileUrl },
              language: { $set: getAudioInfo.language },
              type: { $set: getAudioInfo.type },
              text: { $set: getAudioInfo.text },
            },
          },
        },
      });
    });
  };
  const getIvrText = (infosIvr, language, text, routingName) => {
    const getAudioInfo = {
      type: "text2speech",
      language: language,
      text: text,
      audioFileUrl: decodeURIComponent(infosIvr.url),
    };
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            audio: {
              audioFileUrl: { $set: getAudioInfo.audioFileUrl },
              language: { $set: getAudioInfo.language },
              type: { $set: getAudioInfo.type },
              text: { $set: getAudioInfo.text },
            },
          },
        },
      });
    });
  };
  const setDisconnectIvr = (key, routingName) => {
    let ivrDisconnect = {
      type: "disconnect",
    };
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: { options: { [key]: { $set: ivrDisconnect } } },
        },
      });
    });
  };
  const setIvrName = (name, routingName) => {
    if (!name.includes(" ")) {
      setIVRErrorMessage("");
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { displayName: { $set: name } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: {
              queueId: { $set: "" },
              queueDisplayName: { $set: "" },
            },
          },
        });
      });
    } else {
      setIVRErrorMessage(
        intl.formatMessage({
          id: "The IVR name should not contain a space ' ' ",
        })
      );
    }
  };
  const copyIvr = (routingName, originDialKey, newDialKey) => {
    let newOption = callCenterData.routing[routingName].options[originDialKey];
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: { options: { [newDialKey]: { $set: newOption } } },
        },
      });
    });
  };
  const setPromptName = (name, routingName) => {
    setCallCenterData((state) => {
      return update(state, {
        routing: { [routingName]: { promptDisplayName: { $set: name } } },
      });
    });
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            queueId: { $set: "" },
            queueDisplayName: { $set: "" },
          },
        },
      });
    });
  };
  const removeIvr = (x, routingName) => {
    setCallCenterData((state) => {
      const ivrs = state.routing[routingName].options;
      const asArray = Object.entries(ivrs);
      const atLeast9Wins = asArray.filter(([key, value]) => key !== x);
      const atLeast9WinsObject = Object.fromEntries(atLeast9Wins);
      return update(state, {
        routing: { [routingName]: { options: { $set: atLeast9WinsObject } } },
      });
    });
  };
  const removeAgentFromRecording = (agentId) => {
    let newCallRecordingReaders = callCenterData.callRecordingReaders.filter(
      (data) => {
        return data !== agentId;
      }
    );

    Object.entries(callRecordingReadersDetails).map(([key, value], i) => {
      if (key === agentId) {
        delete callRecordingReadersDetails[key];
      }
    });
    setCallRecordingReaders(newCallRecordingReaders);
    setCallCenterData((state) => {
      return {
        ...state,
        callRecordingReaders: newCallRecordingReaders,
      };
    });

    setCallCenterData((state) => {
      return {
        ...state,
        callRecordingReadersDetails: { ...callRecordingReadersDetails },
      };
    });
  };
  const confirmeRemoveRecordingAgents = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message:
        intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + "!",
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeAgentFromRecording(id),
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  const getItemsRecordingAgents = (RecordingAgents) => {
    if (RecordingAgents) {
      Object.entries(RecordingAgents).map(([key, value]) => {
        let agentName = value;
        let agentkey = key;
        itemsRecordingAgents.push({
          key: agentkey,
          media: <Avatar name={agentName} />,
          header: agentName,
          headerMedia: (
            <Button
              icon={<TrashCanIcon />}
              onClick={(e, data) =>
                confirmeRemoveRecordingAgents(agentkey, agentName)
              }
              text
            />
          ),
        });
      });
      return itemsRecordingAgents;
    } else return [];
  };
  const getItemsadmins = (admins) => {
    if (admins) {
      Object.values(admins).forEach((admin) => {
        let adminName = Object.values(admin)[0];
        let adminkey = Object.keys(admin)[0];
        items.push({
          key: adminkey,
          media: <Avatar name={adminName} />,
          header: adminName,
          headerMedia: (
            <Button
              icon={<TrashCanIcon />}
              onClick={(e, data) =>
                confirmeRemoveAdminUser(adminkey, adminName)
              }
              text
            />
          ),
        });
      });
      return items;
    } else return [];
  };
  const setSelectedHolidaysQueue = (Id, queueDisplayName, itemToUpdate) => {
    setCallCenterData((state) => {
      return update(state, {
        deskOpen: {
          holidays: {
            holidaysAction: {
              queueId: { $set: Id },
              queueDisplayName: { $set: queueDisplayName },
            },
          },
        },
      });
    });
  };
  const setSelectedOpenHoursQueue = (Id, queueDisplayName, itemToUpdate) => {
    setCallCenterData((state) => {
      return update(state, {
        deskOpen: {
          openHours: {
            openHoursAction: {
              queueId: { $set: Id },
              queueDisplayName: { $set: queueDisplayName },
            },
          },
        },
      });
    });
  };
  const addNewRouting = (nameOfNewRouting) => {
    let routingsName = Object.keys(callCenterData.routing);

    if (!nameOfNewRouting.includes(" ") && nameOfNewRouting.length > 0) {
      setRoutingErrorMessage("");
      if (
        !routingsName.includes(nameOfNewRouting) &&
        nameOfNewRouting !== "Start" &&
        nameOfNewRouting !== "0"
      ) {
        setCallCenterData((state) => {
          return update(state, {
            routing: {
              [nameOfNewRouting]: {
                $set: {
                  enabled: true,
                  displayName: "",
                  type: "",
                  queueDisplayName: "",
                  queueId: "",
                  expectedDigitNumber: 1,
                  lastTone: "#",
                  timeout: 10,
                  audio: {
                    type: "text2speech/Audiofile",
                    language: "",
                    text: "",
                    audioFileUrl:
                      "https://clientsorage.blob.core.windows.net/tenants/de5e3c28-141c-4d3e-90ea-aa3326abe67d/b0cedab0-1a02-40b4-8ea4-181571affa51/welcome/Ring05.wav",
                  },
                  options: {},
                  next: { type: "" },
                },
              },
            },
          });
        });
        setIsNewRouting(false);
      }
    } else {
      setRoutingErrorMessage(
        intl.formatMessage({
          id: "ADD_ROUTING_ERROR_MESSAGE",
        })
      );
    }
  };
  const deleteRouting = (nameOfNewRouting) => {
    if (nameOfNewRouting !== "Default") {
      setCallCenterData((state) => {
        const routings = state.routing;
        const asArray = Object.entries(routings);
        const atLeast9Wins = asArray.filter(
          ([key, value]) => key !== nameOfNewRouting
        );
        const atLeast9WinsObject = Object.fromEntries(atLeast9Wins);
        return update(state, { routing: { $set: atLeast9WinsObject } });
      });
    }
  };
  const setRegexpValue = (value, routingName) => {
    setCallCenterData((state) => {
      return update(state, {
        routing: { [routingName]: { regExp: { $set: value } } },
      });
    });
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            queueId: { $set: "" },
            queueDisplayName: { $set: "" },
          },
        },
      });
    });
    setCallCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            queueId: { $set: "" },
            displayName: { $set: "" },
          },
        },
      });
    });
  };
  const setStartTime = (value, routingName) => {
    if (callCenterData.routing[routingName].Time === undefined) {
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: { Time: { $set: { start: "", end: "" } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { Time: { start: { $set: value } } } },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { Time: { start: { $set: value } } } },
        });
      });
    }
  };
  const setEndTime = (value, routingName) => {
    if (callCenterData.routing[routingName].Time === undefined) {
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: { Time: { $set: { start: "", end: "" } } },
          },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { Time: { end: { $set: value } } } },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { Time: { end: { $set: value } } } },
        });
      });
    }
  };
  const setOnMatched = (type, routingName) => {
    let targetType;
    if (type === "transfer to user") {
      type = "transfer";
      targetType = "user";
    }
    if (type === "transfer to phone") {
      type = "transfer";
      targetType = "phone";
    }
    if (callCenterData.routing[routingName].onMatched === undefined) {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onMatched: { $set: {} } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onMatched: { type: { $set: type } } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: { onMatched: { targetType: { $set: targetType } } },
          },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onMatched: { type: { $set: type } } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: { onMatched: { targetType: { $set: targetType } } },
          },
        });
      });
    }
  };
  const setOnUnMatched = (type, routingName) => {
    let targetType;
    if (type === "transfer to user") {
      type = "transfer";
      targetType = "user";
    }
    if (type === "transfer to phone") {
      type = "transfer";
      targetType = "phone";
    }
    if (callCenterData.routing[routingName].onUnMatched === undefined) {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onUnMatched: { $set: {} } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onUnMatched: { type: { $set: type } } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: {
              onUnMatched: { targetType: { $set: targetType } },
            },
          },
        });
      });
    } else {
      setCallCenterData((state) => {
        return update(state, {
          routing: { [routingName]: { onUnMatched: { type: { $set: type } } } },
        });
      });
      setCallCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: {
              onUnMatched: { targetType: { $set: targetType } },
            },
          },
        });
      });
    }
  };
  const setExpectedDigitNumber = (routingName, value) => {
    setCallCenterData((state) => {
      return update(state, {
        routing: { [routingName]: { expectedDigitNumber: { $set: value } } },
      });
    });
  };
  const setLastTone = (routingName, value) => {
    setCallCenterData((state) => {
      return update(state, {
        routing: { [routingName]: { lastTone: { $set: value } } },
      });
    });
  };

  //console.log("callCenterData=====>", callCenterData);
  return (
    <div className="form-container">
      {callCenterData.hasOwnProperty("id") ? (
        <Flex column className="call-flow-div">
          <div className={displayNewCallflow === "hide" ? "show" : "hide"}>
            <Header
              as="h4"
              content={intl.formatMessage({ id: "resource-account" })}
            />
            <Flex style={{ marginLeft: "50px" }}>
              <CallCenterName
                status={status}
                getData={getRoutingRadio}
                itemName={"customerService"}
                displayName={callCenterData.displayName}
                searchactifProfils={false}
              />
            </Flex>
          </div>
          <div className={displayNewCallflow}>
            <Tabs
              defaultActiveKey="service account"
              id="uncontrolled-tab-example"
            >
              <Tab
                eventKey="service account"
                title={intl.formatMessage({ id: "resource-account" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <Header
                      as="h4"
                      content={intl.formatMessage({ id: "time-zone" })}
                    />
                    <TimeZone
                      getData={getTimeZoneData}
                      timeZone={callCenterData.timeZone}
                    />
                  </div>
                  <div style={{ marginLeft: "50px" }}>
                    <Header
                      as="h4"
                      content={intl.formatMessage({ id: "scope" })}
                    />
                    {props.scopeList && props.scopeList !== null ? (
                      <CallflowScope
                        className="child-title-call-flow"
                        isNewCallFlow={
                          params.id === "newCallFlow" ? true : false
                        }
                        scope={callCenterData.scope}
                        scopeList={props.scopeList}
                        setScope={setScope}
                        callCenterData={callCenterData}
                      />
                    ) : null}
                  </div>
                </div>
                <Header
                  as="h4"
                  content={intl.formatMessage({ id: "language" })}
                />
                <Language
                  className="child-title-call-flow"
                  language={callCenterData.language}
                  getData={getLanguageData}
                />
                <Header
                  as="h4"
                  content={intl.formatMessage({ id: "dataLocation" })}
                />
                <DataLocation
                  className="child-title-call-flow"
                  dataLocation={callCenterData.dataLocation}
                  getData={getDataLocation}
                />
              </Tab>
              <Tab
                eventKey="greeting"
                title={intl.formatMessage({ id: "greeting" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <AnnoucementComponent
                  headerTitle="Emergency Message"
                  annoucementName="emergencyMessage"
                  getData={getAnnoucementData}
                  data={callCenterData.greeting.emergencyMessage}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle="Welcome Message"
                  getData={getAnnoucementData}
                  annoucementName="welcomeMessage"
                  data={callCenterData.greeting.welcomeMessage}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle="Flash Message"
                  getData={getAnnoucementData}
                  annoucementName="flashMessage"
                  data={callCenterData.greeting.flashMessage}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle="Custom Message"
                  getData={getAnnoucementData}
                  annoucementName="customMessage"
                  data={callCenterData.greeting.customMessage}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <Divider />
              </Tab>
              <Tab
                eventKey="holidays"
                title={intl.formatMessage({ id: "Holidays" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <AnnoucementComponent
                  getData={getHolidaysData}
                  annoucementName="holidays"
                  headerTitle="Holidays"
                  data={callCenterData.deskOpen.holidays.audio}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <div className="Holidays">
                  <Holidays
                    getData={getHolidaysData}
                    annoucementName="holidays"
                    daysOffList={callCenterData.deskOpen.holidays.daysOff}
                  />
                  <div>
                    <Header
                      as="h5"
                      content={intl.formatMessage({ id: "weekend" })}
                    />
                    <Weekend
                      getData={getHolidaysData}
                      annoucementName="holidays"
                      data={callCenterData.deskOpen.holidays.weekendDays}
                    />
                  </div>
                  <CallMaxWaitTime
                    scope={callCenterData.scope}
                    isCallflow={true}
                    title={intl.formatMessage({ id: "holidays-fallback" })}
                    itemToUpdate="holidays"
                    targetObj={{
                      displayName:
                        callCenterData.deskOpen.holidays.holidaysAction
                          .queueDisplayName,
                    }}
                    queueInfo={callCenterData.deskOpen.holidays.holidaysAction}
                    setSelectedOverflowType={setSelectedOverflowType}
                    setSelectedUserTransfertType={setSelectedUserTransfertType}
                    setSelectedUserTransfertIdForCF={setSelectedUserTransfertId}
                    action={
                      callCenterData.deskOpen.holidays.holidaysAction.type
                    }
                    targetType={
                      callCenterData.deskOpen.holidays.holidaysAction.targetType
                    }
                    overflowType={
                      callCenterData.deskOpen.holidays.holidaysAction.targetType
                    }
                    id={
                      callCenterData.deskOpen.holidays.holidaysAction.targetId
                    }
                    setSelectedHolidaysQueue={setSelectedHolidaysQueue}
                    {...props}
                  />
                </div>

                <AnnoucementComponent
                  headerTitle="Open Hours"
                  annoucementName="openHours"
                  getData={getHolidaysData}
                  data={callCenterData.deskOpen.openHours.audio}
                  audiosList={audioList}
                  callFlowId={callCenterData.id}
                />
                <div>
                  <div className="open-hours">
                    <Openhours
                      getData={getOpenHoursData}
                      data={callCenterData.deskOpen.openHours}
                    />
                  </div>

                  <CallMaxWaitTime
                    scope={callCenterData.scope}
                    className="open-hours-fallback"
                    isCallflow={true}
                    itemToUpdate="openHours"
                    title={intl.formatMessage({ id: "open-hours-fallback" })}
                    targetObj={{
                      displayName:
                        callCenterData.deskOpen.openHours.openHoursAction
                          .queueDisplayName,
                    }}
                    queueInfo={
                      callCenterData.deskOpen.openHours.openHoursAction
                    }
                    setSelectedOpenHoursQueue={setSelectedOpenHoursQueue}
                    setSelectedOverflowType={setSelectedOverflowType}
                    setSelectedUserTransfertType={setSelectedUserTransfertType}
                    setSelectedUserTransfertIdForCF={setSelectedUserTransfertId}
                    action={
                      callCenterData.deskOpen.openHours.openHoursAction.type
                    }
                    overflowType={
                      callCenterData.deskOpen.openHours.openHoursAction
                        .targetType
                    }
                    id={
                      callCenterData.deskOpen.openHours.openHoursAction.targetId
                    }
                    {...props}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="Routing"
                title={intl.formatMessage({ id: "routing" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="action-call-flow">
                  {Object.entries(callCenterData.routing).map(
                    ([key, value]) => {
                      return (
                        <Routing
                          IVRErrorMessage={IVRErrorMessage}
                          key={key}
                          routingName={key}
                          setPromptName={setPromptName}
                          setIvrName={setIvrName}
                          getIvrText={getIvrText}
                          getSelectedIvrData={getSelectedIvrData}
                          getSelectedPromptData={getSelectedPromptData}
                          getRadioData={getRoutingRadio}
                          data={callCenterData.routing[key]}
                          audiosList={audioList}
                          callFlowId={callCenterData.id}
                          removeIvr={removeIvr}
                          setDisconnectIvr={setDisconnectIvr}
                          deleteRouting={deleteRouting}
                          routingList={callCenterData.routing}
                          setRegexpValue={setRegexpValue}
                          setOnMatched={setOnMatched}
                          setOnUnMatched={setOnUnMatched}
                          setStartTime={setStartTime}
                          setEndTime={setEndTime}
                          setExpectedDigitNumber={setExpectedDigitNumber}
                          setLastTone={setLastTone}
                          scope={callCenterData.scope}
                          copyIvr={copyIvr}
                        />
                      );
                    }
                  )}
                  <Button
                    primary
                    icon={<AddIcon />}
                    text
                    content={intl.formatMessage({ id: "add-new-routing" })}
                    onClick={() => setIsNewRouting(true)}
                  />
                  {isNewRouting === true ? (
                    <div>
                      <div style={{ marginBottom: "10px", color: "red" }}>
                        {routingErrorMessage}
                      </div>
                      <Input
                        onChange={(event) => setNewRouting(event.target.value)}
                        style={{ width: "350px" }}
                      ></Input>
                      <Button
                        primary
                        icon={<AddIcon />}
                        text
                        title="Add new routing"
                        onClick={() => addNewRouting(newRouting)}
                      />
                    </div>
                  ) : null}
                </div>
              </Tab>
              <Tab
                eventKey="Functional Admins"
                title={intl.formatMessage({ id: "functional-admins" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="action-call-flow">
                  <Header
                    as="h4"
                    content={intl.formatMessage({ id: "functional-admins" })}
                  />
                  <FunctionAdmins
                    getData={getFunctionAdminsData}
                    FunctionAdmins={callCenterData.functionAdmins}
                  />
                  <List
                    className="functional-admin-list"
                    items={getItemsadmins(functionalAdminsDetails)}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="Recording"
                title={intl.formatMessage({ id: "recording" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="action-call-flow">
                  <Header
                    as="h4"
                    content={intl.formatMessage({ id: "recording" })}
                  />
                  <Recording
                    setRecordingData={setRecordingData}
                    callRecordingEnabled={callCenterData.callRecordingEnabled}
                    setCallRecordingEnabled={setCallRecordingEnabled}
                    setAudioTranscribeEnabled={setAudioTranscribeEnabled}
                    audioTranscribeEnabled={
                      callCenterData.audioTranscribeEnabled
                    }
                    setAudioTranscribeData={setAudioTranscribeData}
                    audioTranscribeDetails={
                      callCenterData.audioTranscribeDetails
                    }
                  />
                  <List
                    id="list-recording-agents"
                    className="functional-admin-list"
                    items={getItemsRecordingAgents(callRecordingReadersDetails)}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="Survey"
                title={intl.formatMessage({ id: "Survey" })}
              >
                <div className="submit-div">
                  <div className="error-div" role="alert"></div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <SurveysComponent
                  headerTitle="Survey"
                  annoucementName="Survey"
                  setCallCenterData={setCallCenterData}
                  audioList={audioList}
                  callCenterData={callCenterData}
                  questions={
                    callCenterData &&
                      callCenterData !== null &&
                      callCenterData.survey &&
                      callCenterData.survey.questions ?
                      callCenterData.survey.questions
                      : {}
                  }
                />
              </Tab>
              {
                callCenterData.reportConfiguration ?
                <Tab
                eventKey="Report"
                title={intl.formatMessage({ id: "Report" })}
               >
                 <div className="submit-div">
                   <div className="error-div" role="alert"></div>
                   <div className="submit-button">
                     <Button
                       className="submit-button"
                       content={intl.formatMessage({ id: "save" })}
                       onClick={sendData}
                       primary
                       loading={isLoading}
                       disabled={isDisabled}
                     />
                   </div>
                 </div>
                 <div className="action-call-flow">
                   <ReportComponent 
                   reportConfiguration={callCenterData.reportConfiguration}
                   setCallCenterData={setCallCenterData}
                   scope={callCenterData.scope}
                   />
                   </div>
                
               </Tab>
                :
                null
              }

              <Tab disabled title={callCenterData.displayName} />

            </Tabs>
          </div>
        </Flex>
      ) : (
        <Loader />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  scopeList: state.scopeList,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallCenterContainer);

import React, { useEffect, useState } from "react";
import { List, Button } from "@fluentui/react-northstar";
import { ArrowUpIcon } from "@fluentui/react-icons-northstar";

const AgentQueues = (props) => {
    const [agentQueueIds, setAgentQueueIds] = useState([]);
    const [queueList, setQueueList] = useState([]);

    const formatQueuesList = (agentQueueDetails) => {
        // Map the queue details to formatted items for the list
        const formattedList = agentQueueDetails.map((queue, index) => ({
            key: queue,
            header: props.agent.agentQueueDetails[queue]?.displayName || "Unknown",
            headerMedia: (
                <div>
                    <Button
                        icon={<ArrowUpIcon />}
                        text
                        iconOnly
                        title="Move Up"
                        disabled={index === 0}
                        onClick={() => handleMoveUp(queue, index)}
                    />
                </div>
            ),
        }));
        setQueueList(formattedList); // Replace the entire list
    };

    const handleMoveUp = (queue, index) => {
        const updatedQueueIds = [...agentQueueIds];
        const [element] = updatedQueueIds.splice(index, 1); // Remove the item at `index`
        updatedQueueIds.splice(index - 1, 0, element); // Insert it at the new position

        setAgentQueueIds(updatedQueueIds); // Update the queue order
        props.setAgent({ ...props.agent, agentQueueIds: updatedQueueIds }); // Notify the parent
    };

    useEffect(() => {
        if (props.agent?.agentQueueIds?.length > 0) {
            setAgentQueueIds(props.agent.agentQueueIds);
            formatQueuesList(props.agent.agentQueueIds);
        }
    }, [props.agent]);

    useEffect(() => {
        formatQueuesList(agentQueueIds);
    }, [agentQueueIds]);

    return (
        <div style={{ width: "50%" }}>
            <List items={queueList} />
        </div>
    );
};

export default AgentQueues;
